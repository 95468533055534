<template>
  <div>
    <PageHeader
      v-if="group"
      :title="`Groupes > ${group.label}`"
      :description="`Gestion des utilisateurs`"
    />

    <!-- <UsersSubmenu /> -->

    <PageContent>
      <MVPTable
        :grid-data="gridData"
        :options="gridOptions"
      />
    </PageContent>
  </div>
</template>

<script>
import { http } from '@/services/api.service'
import PageHeader from '@/components/PageHeader.vue'
import PageContent from '@/components/PageContent.vue'
import MVPTable from '@/components/agGrid/MVPTable.vue'

export default {
  name: 'app-users-list',
  components: {
    PageHeader,
    PageContent,
    MVPTable
  },
  computed: {
    domain () {
      return this.$store.getters.domain
    }
  },
  data () {
    return {
      loading: false,
      storageUrl: process.env.VUE_APP_STORAGE_URL,
      users: [],
      group: {},
      groups: [],
      gridData: null,
      gridOptions: {
        columnDefs: [
          {
            headerName: '#',
            cellRenderer: 'agCheckboxCellRenderer',
            cellEditor: 'agCheckboxCellEditor',
            editable: true,
            width: 40,
            valueGetter: this.userIsInGroup,
            valueSetter: this.userGroupSwitch
          },
          {
            headerName: 'Nom',
            field: 'lastname',
            filter: 'agTextColumnFilter',
            sortable: true
          },
          {
            headerName: 'Prénom',
            field: 'firstname',
            filter: 'agTextColumnFilter',
            sortable: true
          },
          {
            headerName: 'Adresse e-mail',
            field: 'email',
            filter: 'agTextColumnFilter',
            sortable: true
          }
        ]
      }
    }
  },
  created () {
    this.loadGroups()
    this.loadUsers()
  },
  watch: {
    domain (oldValue, newValue) {
      this.$router.push({ name: 'app-groups' })
    },
    $route (to, from) {
      this.group = this.groups.filter(group => group.uuid === this.$route.params.uuid)
    }
  },
  methods: {
    loadGroups () {
      http
        .get(`groups`)
        .then(res => {
          this.groups = res.data
          this.group = res.data.find(group => group.uuid === this.$route.params.uuid)
        })
    },

    loadUsers () {
      this.loading = true
      http.get(`/user/eligibles-for-user-groups`).then(res => { // lists classic users but also operators and directors
        this.loading = false
        this.users = res.data
        this.gridData = res.data
        // this.buildTable(res.data)
      }).catch(err => {
        console.log(err)
        this.loading = false
        this.$buefy.notification.open({
          duration: 3000,
          message: err.response.data.message,
          position: 'is-bottom-left',
          type: 'is-warning',
          hasIcon: true,
          icon: 'server'
        })
      })
    },

    userIsInGroup (params) {
      return Boolean(params.data.groups.some(group =>
        group.uuid === this.group.uuid
      ))
    },

    userGroupSwitch (params) {
      if (params.newValue) {
        http
          .post(`/groups/${this.group.uuid}/user`, {
            user: params.data.uuid
          })
      } else {
        http
          .delete(`/groups/${this.group.uuid}/user/${params.data.uuid}`)
      }
    }
  }
}
</script>
